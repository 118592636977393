<template>
  <div class="pageContol listWrap templateList templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程结算</a>
        </span>
      </div>
      <div class="framePage-body">
        <div>
          <el-row :gutter="20" class="mabtm">
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                size="small"
                v-model="courseName"
                type="text"
                placeholder="请输入课程名称"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">课程来源:</span>
              <el-select
                size="small"
                style="width:100%"
                v-model="courseOriginId"
                remote
                type="text"
                :remote-method="getCompSourceList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in compSourceList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">使用机构:</span>
              <el-select
                size="small"
                style="width:100%"
                v-model="compId"
                remote
                :remote-method="getCompSourceList"
                type="text"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in compSourceList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">班级名称:</span>
              <el-input
                size="small"
                v-model="projectName"
                type="text"
                placeholder="请输入班级名称"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">班级编码:</span>
              <el-input
                size="small"
                v-model="projectCode"
                type="text"
                placeholder="请输入班级编码"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="9" :lg="6" class="searchboxItem">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-col>
            <el-col :sm="12" :md="9" :lg="6" class="searchboxItem">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-col>
            <el-col :sm="12" :md="9" :lg="6" class="searchboxItem">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </el-col>
            <el-col :sm="12" :md="6" :lg="6" class="df searchboxItem">
              <el-button type="primary" size="small" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="exportProjectUserExcel()"
              >导出</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column
                label="课程来源机构"
                align="left"
                show-overflow-tooltip
                prop="courseOrigin"
                width="260px"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                width="260px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="160"
              >
                <template slot-scope="scope">{{scope.row.trainTypeNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column
                label="使用机构"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="300px"
              />
              <el-table-column
                label="课程总学时"
                align="right"
                show-overflow-tooltip
                prop="totalLessNum"
                width="100"
              />
              <el-table-column
                label="使用学时"
                align="right"
                show-overflow-tooltip
                prop="useLessNum"
                width="100"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="300px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="200px"
              />
              <el-table-column
                label="班级计划培训人数"
                align="right"
                show-overflow-tooltip
                prop="projectPeople"
                width="150px"
              />
              <el-table-column
                label="课程完成人数"
                align="right"
                show-overflow-tooltip
                prop="courseCompleteNum"
                width="100px"
              />
              <el-table-column
                label="开始时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                  scope.row.startDate|momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                  scope.row.endDate|momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="行业类型" align="left" show-overflow-tooltip width="160">
                <template slot-scope="scope">{{scope.row.industryNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="岗位类型" align="left" show-overflow-tooltip width="160">
                <template slot-scope="scope">{{scope.row.postName || '--'}}</template>
              </el-table-column>
              <el-table-column label="职业/工种" align="left" show-overflow-tooltip width="160">
                <template slot-scope="scope">{{scope.row.occupationName || '--'}}</template>
              </el-table-column>
              <el-table-column label="培训等级" align="left" show-overflow-tooltip width="160">
                <template slot-scope="scope">{{scope.row.trainLevelName || '--'}}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="seeDetails(scope.row.projectId,pageNum,pageSize,scope.row.projectCourseId,scope.row.useKpointNum,scope.row.useLessNum)"
                  >使用详情</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
// import tree from "@/components/treePopup";
import tree from "@/components/treePopup";
export default {
  name: "evaluate/CoursesettlementList",
  components: {
    Empty,
    PageNum,
    tree
  },
  data() {
    return {
      compId: "",
      courseName: "",
      compName: "",
      courseOriginId: "",
      startTime: [],
      endTime: [],
      compSourceList: "",
      projectCode: "",
      projectName: "",
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  mixins: [List],
  created: function() {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    exportProjectUserExcel() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消"
        }
      )
        .then(() => {
          const params = {
            // pageNum: pageNum,
            // pageSize: this.pageSize
          };
          if (this.compId) {
            params.compId = this.compId;
          }
          if (this.courseName) {
            params.courseName = this.courseName;
          }
          if (this.courseOriginId) {
            params.courseOriginId = this.courseOriginId;
          }
          if (this.projectCode) {
            params.projectCode = this.projectCode;
          }
          if (this.projectName) {
            params.projectName = this.projectName;
          }

          if (this.startTime) {
            params.startStartDate = this.startTime[0];
            params.startEndDate = this.startTime[1];
          }
          if (this.endTime) {
            params.endStartDate = this.endTime[0];
            params.endEndDate = this.endTime[1];
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          this.$post("/run/project/exportCourseSettleList", params).then(
            res => {
              if (res.status == "0") {
                window.location.href = res.data;
              }
            }
          );
        })
        .catch(() => {});
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.courseOriginId) {
        params.courseOriginId = this.courseOriginId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }

      if (this.startTime && this.startTime.length > 0) {
        params.startStartDate = this.startTime[0];
        params.startEndDate = this.startTime[1];
      }
      if (this.endTime && this.endTime.length > 0) {
        params.endStartDate = this.endTime[0];
        params.endEndDate = this.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/project/queryCourseSettleList",
        params,
        pageNum
      });
    },
    seeDetails(
      projectId,
      pageNum,
      pageSize,
      projectCourseId,
      useKpointNum,
      useLessNum
    ) {
      this.$router.push({
        path: "/web/evaluate/CoursesettlementDatails",
        query: {
          projectId,
          pageNum,
          pageSize,
          projectCourseId,
          useKpointNum,
          useLessNum
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 45 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 资源提供者
    getCompSourceList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.compSourceList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.compSourceList = [];
      }
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>

<style lang="less" scoped>
.templateList1 {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    .bgc-bv {
      width: 72px;
    }
    .el-cascader {
      height: 32px !important;
    }
    .itemLabel {
      width: 100px;
    }
  }
}
</style>
